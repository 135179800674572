import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { TitleSecondary } from '@components/styled/Typography';
const Container = styled('div', { shouldForwardProp: propName => isPropValid(propName) })(props => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `${props.theme.customColors.surface.surface}`,
    padding: `${props.theme.spacing_sizes.s * 5}px 
                ${props.theme.spacing_sizes.s * 5}px  
                ${props.theme.spacing_sizes.s * 3}px`,
    borderRadius: `${props.theme.spacing_sizes.xs * 1.25}px`,
    boxShadow: '0px 4px 8px -6px rgba(24, 39, 75, 0.04)',
    gap: props.gap || `${props.theme.spacing_sizes.l}px`,
}));
const BlockTitle = styled(TitleSecondary)(props => ({
    lineHeight: '22px',
    padding: `${props.theme.spacing_sizes.xs * 0.5}px 0`,
}));
const BlockContainer = ({ title = undefined, children = null, className = undefined, gap = undefined, }) => (_jsxs(Container, { className: className, gap: gap, children: [!!title && (_jsx(BlockTitle, { children: title })), children] }));
export default BlockContainer;
