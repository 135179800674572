import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Tabs from '@shared/ui/navigation/Tabs';
import { ContainerColumn, NarrowSection } from '@components/styled';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import getSettingsTabs from '../../lib/constants';
import { matchPathToSettingsTab } from '../../lib/utils';
const Container = styled(ContainerColumn)(() => ({
    alignItems: 'center',
    paddingTop: '30px',
    paddingBottom: '30px',
}));
const TabsContainer = styled.div(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 3,
}));
const NarrowContainer = NarrowSection.withComponent(ContainerColumn);
const Settings = () => {
    const { pathname: locationPathname } = useLocation();
    const { routes, navigate } = useAppNavigation();
    const currentTab = useMemo(() => matchPathToSettingsTab(locationPathname), [locationPathname]);
    const settingsTabs = getSettingsTabs();
    const onTabChange = (selectedTab) => {
        let routeName;
        switch (selectedTab) {
            case 'personal_information':
                routeName = routes.SETTINGS_PERSONAL_INFORMATION;
                break;
            case 'subscription':
                routeName = routes.SETTINGS_SUBSCRIPTION;
                break;
            default:
                break;
        }
        if (routeName) {
            navigate(routeName);
        }
    };
    useEffect(() => {
        const matchedTab = matchPathToSettingsTab(locationPathname);
        if (!matchedTab) {
            navigate(routes.SETTINGS_PERSONAL_INFORMATION, { replace: true });
        }
    }, [
        locationPathname,
        routes,
        navigate,
    ]);
    if (!currentTab) {
        return null;
    }
    return (_jsx(Container, { children: _jsxs(NarrowContainer, { children: [_jsx(TabsContainer, { children: _jsx(Tabs, { activeTab: currentTab, tabs: settingsTabs, onChange: onTabChange }) }), _jsx(Outlet, {})] }) }));
};
export default Settings;
